export default {
  en: {
    language: "English",
    identifier: "EN",
    startTextFirst: "Answer some simple questions about your status and circumstances.",
    startTextSecond: "After you have answered all the questions you will receive personalized instructions on how to proceed and which documents to bring with you.",
    welcome: "Welcome",
    startButtonText: "Start",
    headerTourist: "Note",
    header: "Thank You",
    touristMessage: "Please note that it is not possible for you to receive service at International House Helsinki if you are in Finland as a tourist. We wish you a pleasant stay!",
    goHome: "Go home",
    startAgain: "Start again",
    close:"Close",
    back: "Go back",
    continue:"Continue",
    closeApp:"Do you want to close the app?",
    closeAppWarn:"If you choose to close the app, you will return to the home page.",
    t404:"The page you are looking for cannot be found",
    viewPdf:"View PDF-file",
    downloadPdf: "Download PDF-file",
    pdfSize: "(file size approximately 1 Megabyte)",
    finalWords: "You have now completed the questionnaire. Please print your checklist and follow instructions. The application will close in 60 seconds.",
    disclaimer: "Legal disclaimer",
    sendAsEmail: "Send as email",
    send: "Send",
    invalidEmail: "Invalid email address",
    emailSent: "Email sent",
    emailSendFailed: "Sending email failed",
    print: "Print"
  },
  fi: {
    language: "Suomi",
    identifier: "FI",
    startTextFirst: "Vastaa joitakin yksinkertaisia kysymyksiä tilasta ja olosuhteista.",
    startTextSecond: "Kun olet vastannut kaikkiin kysymyksiin, saat henkilökohtaisia \u200B\u200Bohjeita siitä, miten voit edetä ja mitä asiakirjoja tuoda mukanasi.",
    welcome: "Tervetuloa",
    startButtonText: "Aloita!",
    headerTourist: "Huomaa",
    header: "Kiitos",
    touristMessage: "Huomaa, että ei ole mahdollista saada palvelua International House Helsingissä, jos olet Suomessa matkailijana. Toivotamme miellyttävää oleskelua!",
    goHome: "Kotiin",
    startAgain: "Aloittaa uudelleen",
    close:"Kiini",
    back: "Takaisin",
    continue:"Jatkaa",
    closeApp:"Haluatko sulkea sovelluksen?",
    closeAppWarn:"Jos päätät sulkea sovelluksen, palaat kotisivulle.",
    t404:"Etsimääsi sivua ei löydy",
    viewPdf:"Näytä PDF",
    downloadPdf: "Lataa PDF (noin 1 Megabyte)",
    finalWords: "You have now completed the questionnaire. Please print your checklist and follow instructions. The application will close in 60 seconds.",
    disclaimer: "Legal disclaimer",
    sendAsEmail: "Send as email",
    send: "Send",
    invalidEmail: "Invalid email address",
    emailSent: "Email sent",
    emailSendFailed: "Sending email failed",
    print: "Print"
  },
  ru: {
    language: "Pусский",
    identifier: "RU",
    startTextFirst: "Ответьте на несколько простых вопросов о вашем статусе и обстоятельствах.",
    startTextSecond: "После того, как вы ответите на все вопросы, вы получите персональные инструкции о том, как действовать и какие документы взять с собой.",
    welcome: "добро пожаловать",
    startButtonText: "начало!",
    headerTourist: "Пожалуйста, обратите внимание",
    header:"Спасибо",
    touristMessage: "Обратите внимание, что вы не можете получить услугу в International House Helsinki, если вы в Финляндии как турист. Мы желаем вам приятного пребывания!",
    goHome: "Иди домой",
    startAgain: "Начать снова",
    close:"близко",
    back: "назад",
    continue:"Продолжить",
    closeApp:"Вы хотите закрыть приложение?",
    closeAppWarn:"Если вы решите закрыть приложение, вы вернетесь к начальному экрану",
    t404:"Страница, которую вы ищете, не может быть найдена",
    viewPdf:"Пw",
    downloadPdf: "Скачать PDF (размер файла приблизительно 1 Мбайт)",
    finalWords: "You have now completed the questionnaire. Please print your checklist and follow instructions. The application will close in 60 seconds.",
    disclaimer: "Legal disclaimer",
    sendAsEmail: "Send as email",
    send: "Send",
    invalidEmail: "Invalid email address",
    emailSent: "Email sent",
    emailSendFailed: "Sending email failed",
    print: "Print"
  },
  se: {
    language: "Svenska",
    identifier: "SE",
    startTextFirst: "Besvara några enkla frågor om din status och omständigheter.",
    startTextSecond: "När du har svarat på alla frågor får du personliga instruktioner om hur du ska fortsätta och vilka dokument som ska hämtas med dig.",
    welcome: "Välkommen",
    startButtonText: "Start!",
    headerTourist: "Vänligen notera",
    header:"Tack",
    touristMessage: "Observera att det inte går att få service på International House Helsinki om du är i Finland som turist. Vi önskar dig en trevlig vistelse!",
    goHome: "Gå hem",
    startAgain: "Börja om",
    close:"Stänga",
    back: "Tillbaka",
    continue:"Fortsätta",
    closeApp:"Vill du stänga appen?",
    closeAppWarn:"Om du väljer att stänga appen kommer du tillbaka till startskärmen.",
    t404:"Sidan du söker kan inte hittas",
    viewPdf:"Visa PDF",
    downloadPdf: "Ladda ner PDF (cirka 1 megabyte)",
    finalWords: "You have now completed the questionnaire. Please print your checklist and follow instructions. The application will close in 60 seconds.",
    disclaimer: "Legal disclaimer",
    sendAsEmail: "Send as email",
    send: "Send",
    invalidEmail: "Invalid email address",
    emailSent: "Email sent",
    emailSendFailed: "Sending email failed",
    print: "Print"
  }
};
